import { useActions, useReduxState } from "re-reduced";
import React, { useState } from "react";

import ManualBarcodeEntry from "@components/settings/manualBarcodeEntry";
import ModeDropdown from "@components/settings/modeDropdown";
import ConfirmModal from "@components/shared/confirmModal";
import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { Mode } from "@state/types";
import { Button, TextInput } from "@sub";
import { Container, GridContainer, H2 } from "@util/standard";



interface State {
  ipAddress: string;
  port: string;
}

const Settings = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [state, setState] = useState<State>({
    ipAddress: "",
    port: "",
  });
  const {
    logout,
    setMode,
  } = useActions(actions);
  const {
    loggedIn,
    mode,
    selectedEvent
  } = useReduxState({
    selectedEvent: selectors.getSelectedEvent,
    loggedIn: selectors.getLoggedIn,
    mode: selectors.getMode,
  });
  const [currentMode, setCurrentMode] = useState<Mode>(mode);

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleYesClick = () => {
    setModalVisible(false);
    logout();
  };

  const handleSubmitClick = () => {

    if (state.ipAddress !== "" && currentMode === "local") {
      const { ipAddress, port } = state;
      const params = { ipAddress, port };

      setMode({ mode: "local", params });
      return;
    }

    setMode({ mode: "online" });
  };

  return (
    <Container flexDirection="column" margin="0 30px">
      <title>Settings</title>
      <Container flexDirection="column">
        <H2>Settings</H2>
        <GridContainer repeat={1} mobileRepeat={1} rowGap={15}>
          <ModeDropdown
            currentMode={currentMode}
            setCurrentMode={setCurrentMode}
          />
          {currentMode === "local" && (
            <TextInput
              name="ipAddress"
              placeholderText="Enter Local IP Address"
              value={state.ipAddress}
              onChange={handleInputChange}
            />
          )}
          {currentMode === "local" && (
            <TextInput
              name="port"
              placeholderText="Enter Port Number"
              value={state.port}
              onChange={handleInputChange}
            />
          )}

          <Button
            theme="base"
            text="SUBMIT"
            fontSize={15}
            onClick={handleSubmitClick}
          />
        </GridContainer>

        {loggedIn && selectedEvent && <ManualBarcodeEntry />}

        <ConfirmModal
          modalVisible={modalVisible}
          onClose={handleModalClose}
          onYes={handleYesClick}
          text={
            "Are you sure you want to logout?"
          }
        />
      </Container>
    </Container>
  );
};

export default Settings;
