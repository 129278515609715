import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { useReduxState } from "re-reduced";

import { Container, P, Select } from "@util/standard";
import { Mode } from "@state/types";
import * as selectors from "@state/selectors";

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 100%;
`;

const modes: Mode[] = ["online", "local"];

interface Props {
  currentMode: Mode;
  setCurrentMode: Dispatch<SetStateAction<Mode>>;
}

const ModeDropdown = (props: Props) => {
  const { storedMode } = useReduxState({
    storedMode: selectors.getMode,
  });

  return (
    <Wrapper>
      <P>{`Current Mode: ${storedMode.toUpperCase()}`}</P>
      <Select
        name="mode_list"
        id="mode_list"
        onChange={(e) => props.setCurrentMode(e.target.value as Mode)}
        value={props.currentMode}
      >
        {modes.map((mode) => {
          return (
            <option value={mode} key={mode}>
              {mode.toUpperCase()}
            </option>
          );
        })}
      </Select>
    </Wrapper>
  );
};

export default ModeDropdown;
