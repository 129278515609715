import { useActions, useReduxState } from "re-reduced";
import React, { useState } from "react";
import styled from "styled-components";

import GateDropdown from "@components/scanner/GateDropdown";
import ResponseModal from "@components/scanner/responseModal";
import ScanDirectionDropdown from "@components/scanner/scanDirectionDropdown";
import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { Button, TextInput } from "@sub";
import { colorsRGB } from "@util/constants";
import {
  Container,
  GiveMeSomeSpace,
  GridContainer,
  H3,
  P
} from "@util/standard";

const Title = styled(P)`
  color: ${colorsRGB.white(0.5)};
  font-weight: bold;
  margin: 5px 0;
`;

const ManualBarcodeEntry = () => {
  const [input, setInput] = useState<string>();
  const [modalVisible, setModalVisible] = useState(false);

  const { gateId, status, errorMsg, ticket } = useReduxState({
    gateId: selectors.getGateId,
    status: selectors.getScannedTicketStatus,
    errorMsg: selectors.getScannedTicketErrorMsg,
    ticket: selectors.getScannedTicketTicket,
  });
  const { scanTicket, clearScanTicket } = useActions(actions);

  const handleModalClose = () => {
    setModalVisible(false);
    clearScanTicket();
  };

  const handleNextClick = () => {
    setModalVisible(true);
    if (input && input.length > 0 && gateId) {
      scanTicket({ ticketNumber: input });
    }
  };

  function handleInputChange(event: any) {
    const value = event.target.value;
    setInput(value);
  }

  return (
    <Container flexDirection="column">
      <H3>Manual barcode entry</H3>
      <GridContainer repeat={2} mobileRepeat={2} columnGap={20}>
        <Container flexDirection="column" width="100%">
          <Title>GATE</Title>
          <GateDropdown />
        </Container>
        <Container flexDirection="column" width="100%">
          <Title>SCAN MODE</Title>
          <ScanDirectionDropdown />
        </Container>
      </GridContainer>
      <GiveMeSomeSpace />
      <Container width="100%">
        <TextInput
          dimensions={{ width: "inherit" }}
          placeholderText="QR code number"
          type="tel"
          onChange={(event) => handleInputChange(event)}
        />
        <Button
          theme="base"
          text="NEXT"
          width="100px"
          fontSize={15}
          onClick={handleNextClick}
        />
      </Container>
      <ResponseModal
        status={status!}
        errorMsg={errorMsg}
        ticket={ticket}
        handleModalClose={handleModalClose}
        modalVisible={modalVisible}
      />
    </Container>
  );
};

export default ManualBarcodeEntry;
