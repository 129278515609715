import { useActions, useReduxState } from "re-reduced";
import React, { useEffect } from "react";

import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { Select } from "@util/standard";

const GateDropdown = () => {
  const { gates, gateId } = useReduxState({
    gates: selectors.getGates,
    gateId: selectors.getGateId,
  });
  const { selectGateId } = useActions(actions);

  useEffect(() => {
    if (gates && gates?.length > 0 && gateId === undefined) {
      selectGateId(gates[0].id);
    }
  }, [gates]);

  return (
    <Select
      name="gate_list"
      id="gate_list"
      onChange={(e) => selectGateId(e.target.value)}
      value={gateId}
    >
      {gates &&
        gates.map((gate) => {
          return (
            <option value={gate.id} key={gate.id}>
              {gate.name}
            </option>
          );
        })}
    </Select>
  );
};

export default GateDropdown;
