import React from "react";
import { useActions, useReduxState } from "re-reduced";

import * as selectors from "@state/selectors";
import actions from "@state/actions";
import { Select } from "@util/standard";
import { TicketScanDirection } from "@graphql/sdk";

const ScanDirectionDropdown = () => {
  const { scanDirection } = useReduxState({
    scanDirection: selectors.getScanDirection,
  });
  const { setScanDirection } = useActions(actions);

  return (
    <Select
      name="scan_direction"
      id="scan_direction"
      onChange={(e) => setScanDirection(e.target.value as TicketScanDirection)}
      value={scanDirection}
    >
      <option
        value={TicketScanDirection.ScanIn}
        key={TicketScanDirection.ScanIn}
      >
        Scan In
      </option>
      <option
        value={TicketScanDirection.ScanOut}
        key={TicketScanDirection.ScanOut}
      >
        Scan Out
      </option>
    </Select>
  );
};

export default ScanDirectionDropdown;
